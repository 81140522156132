<template>
    <div>
        <!-- #region Generate Hackathon Modal  -->
        <b-modal
            id="generate_hackathon_modal"
            title="Generate a hackathon"
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div>
                <b-row>
                    <b-col>
                        <validation-observer
                            v-slot="{ handleSubmit }"
                            ref="formValidator"
                        >
                            <b-form
                                role="form"
                                @submit.prevent="handleSubmit(submit_form)"
                            >
                                <!-- Hackathon Purpose  -->
                                <b-row>
                                    <b-col>
                                        <base-input
                                            :pill="true"
                                            label="Hackathon Purpose*"
                                            class="mb-3"
                                            name="Hackathon Purpose"
                                            :rules="{ required: true }"
                                            placeholder="The purpose of this hackathon"
                                            v-model="
                                                modals.generate_hackathon_modal
                                                    .form.values
                                                    .hackathon_purpose
                                            "
                                            :disabled="$apollo.loading"
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                                <!-- Hackathon Participant Types  -->
                                <b-row>
                                    <b-col>
                                        <base-input
                                            label="Types of participants*"
                                            :rules="{
                                                required: true,
                                            }"
                                            :pill="true"
                                            name="Participant Types"
                                            :disabled="$apollo.loading"
                                            placeholder="e.g. Graphic Designers, UI/UX Engineers and Data Sientists"
                                            v-model="
                                                modals.generate_hackathon_modal
                                                    .form.values
                                                    .participant_types.value
                                            "
                                        >
                                            <!-- <el-select
                                                class="form"
                                                v-model="
                                                    modals
                                                        .generate_hackathon_modal
                                                        .form.values
                                                        .participant_types.value
                                                "
                                                :filterable="true"
                                                :disabled="$apollo.loading"
                                            >
                                                <el-option
                                                    v-for="option in modals
                                                        .generate_hackathon_modal
                                                        .form.values
                                                        .participant_types
                                                        .options"
                                                    :key="option.name"
                                                    :label="option.name"
                                                    :value="option.name"
                                                >
                                                </el-option>
                                            </el-select> -->
                                        </base-input>
                                    </b-col>
                                </b-row>

                                <!-- Company Type  -->
                                <b-row>
                                    <b-col>
                                        <base-input
                                            label="What name describes your organization best?*"
                                            :rules="{
                                                required: true,
                                            }"
                                            :pill="true"
                                            name="Organization Type"
                                            :disabled="$apollo.loading"
                                            placeholder="e.g. 'Software Development Agency' or 'Insurance Company'"
                                            v-model="
                                                modals.generate_hackathon_modal
                                                    .form.values
                                                    .organization_type.value
                                            "
                                        >
                                            <!-- <el-select
                                                class="form"
                                                v-model="
                                                    modals
                                                        .generate_hackathon_modal
                                                        .form.values
                                                        .organization_type.value
                                                "
                                                :filterable="true"
                                                :disabled="$apollo.loading"
                                            >
                                                <el-option
                                                    v-for="option in modals
                                                        .generate_hackathon_modal
                                                        .form.values
                                                        .organization_type
                                                        .options"
                                                    :key="option.name"
                                                    :label="option.name"
                                                    :value="option.name"
                                                >
                                                </el-option>
                                            </el-select> -->
                                        </base-input>
                                    </b-col>
                                </b-row>

                                <!-- Hackathon Goals  -->
                                <b-row>
                                    <b-col>
                                        <base-input
                                            :pill="true"
                                            label="What would the goal for this hackathon?*"
                                            class="mb-3"
                                            name="Hackathon Purpose"
                                            :rules="{ required: true }"
                                            placeholder="e.g. Develop a new product or get new customers"
                                            v-model="
                                                modals.generate_hackathon_modal
                                                    .form.values.hackathon_goals
                                            "
                                            :disabled="$apollo.loading"
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>

                                <!-- Submit Button -->
                                <b-row class="text-right">
                                    <b-col>
                                        <base-button
                                            native-type="submit"
                                            type="primary"
                                            :pill="true"
                                            class="my-4 btn-success"
                                            :success="
                                                modals.generate_hackathon_modal
                                                    .success
                                            "
                                            :loading="
                                                modals.generate_hackathon_modal
                                                    .loading
                                            "
                                            >Generate
                                        </base-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <!-- #endregion -->

        <!-- #region Generate Hackathon Modal  -->
        <b-modal
            id="generate_hackathon_results_modal"
            title="Hackathon AI"
            size="lg"
            hide-footer
        >
            <div>
                <b-row class="mt--5">
                    <b-col>
                        <p>
                            This is a list of your AI Generated hackathon
                            options based on the information provided. You can
                            select an option that you like as a starting point
                            for your hackathon.
                        </p>
                    </b-col>
                </b-row>
                <b-row class="pt-3 pb-2">
                    <b-col>
                        <h2>Hackathon Options</h2>
                    </b-col>
                </b-row>
                <b-row v-for="(option, index) in ai.response" :key="index">
                    <b-col>
                        <b-card>
                            <b-row>
                                <b-col>
                                    <h3>
                                        {{ option.hackathon_name }}
                                    </h3>
                                </b-col>
                                <b-col sm="auto">
                                    <a
                                        v-b-toggle
                                        :href="`#hackathonOption${index}`"
                                        @click.prevent
                                    >
                                        <span class="when-opened">
                                            <i class="fas fa-chevron-up"></i>
                                        </span>
                                        <span class="when-closed">
                                            <i
                                                class="fas fa-chevron-down"
                                            ></i> </span
                                    ></a>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p>
                                        {{ option.hackathon_short_description }}
                                    </p>
                                </b-col>
                            </b-row>

                            <b-collapse
                                :id="`hackathonOption${index}`"
                                role="tabpanel"
                            >
                                <b-row class="mt-2">
                                    <b-col>
                                        <h4>Description</h4>
                                        <p>
                                            {{
                                                option.hackathon_detail_description
                                            }}
                                        </p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <h4>Goals</h4>
                                        <ol>
                                            <li
                                                v-for="(
                                                    goal_option, index
                                                ) in option.hackathon_goals"
                                                :key="index"
                                            >
                                                {{ goal_option.goal }}
                                            </li>
                                        </ol>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <h4>Judging Criteria</h4>
                                        <ul>
                                            <li
                                                v-for="(
                                                    criteria_option, index
                                                ) in option.hackathon_judging_criteria"
                                                :key="index"
                                            >
                                                <b-row>
                                                    <b-col>
                                                        {{
                                                            criteria_option.criteria
                                                        }}
                                                    </b-col>
                                                    <b-col sm="auto">
                                                        {{
                                                            criteria_option.max_score
                                                        }}
                                                        Points
                                                    </b-col>
                                                </b-row>
                                            </li>
                                        </ul>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-3 text-right">
                                    <b-col></b-col>
                                    <b-col sm="auto" class="mx-0 px-0">
                                        <el-tooltip
                                            content="Use this hackathon as a starting point for you to edit"
                                            placement="top"
                                        >
                                            <base-button
                                                type="primary"
                                                :pill="true"
                                                size="sm"
                                                @click="
                                                    create_hackathon(option)
                                                "
                                                :loading="
                                                    modals
                                                        .generate_hackathon_results_modal
                                                        .loading
                                                "
                                                :disabled="
                                                    modals
                                                        .generate_hackathon_results_modal
                                                        .loading
                                                "
                                                >Start Editing
                                            </base-button>
                                        </el-tooltip>
                                    </b-col>
                                </b-row>
                            </b-collapse>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <!-- #endregion -->

        <!-- #region side card -->
        <b-card>
            <b-row>
                <b-col>
                    <b-row>
                        <b-col>
                            <h3>
                                <span class="btn-inner--icon p-1">
                                    <img src="img/icons/common/ai_stars.svg" />
                                </span>
                                Hackathon AI
                            </h3>
                        </b-col>
                    </b-row>
                    <div v-if="ai.query_id === null">
                        <b-row>
                            <b-col>
                                <p>
                                    Try out our Hackathon AI to generate a
                                    hackathon for you.
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3 text-right">
                            <b-col>
                                <base-button
                                    type="primary"
                                    :pill="true"
                                    size="sm"
                                    @click="
                                        $bvModal.show(
                                            'generate_hackathon_modal'
                                        )
                                    "
                                    >Get Started
                                </base-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div
                        v-else-if="ai.query_id !== null && ai.response === null"
                    >
                        <b-row>
                            <b-col>
                                <p>
                                    Generating your hackathon. This takes about
                                    2 minutes.
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col>
                                <base-progress
                                    type="primary"
                                    :value="progress_bar.current_value"
                                    :height="10"
                                >
                                </base-progress>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-center">
                                <Transition>
                                    <h1>
                                        <i
                                            class="fas fa-spinner fa-spin mt-2 text-muted"
                                        ></i>
                                    </h1>
                                </Transition>
                            </b-col>
                        </b-row>
                    </div>
                    <!-- <div v-else> -->
                    <div v-else-if="ai.response !== null">
                        <b-row>
                            <b-col>
                                <p>
                                    Your AI generated hackathon is ready, you
                                    have 3 options to choose from.
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3 text-right">
                            <b-col></b-col>
                            <b-col sm="auto" class="mx-0 px-0">
                                <base-button
                                    type="primary"
                                    :pill="true"
                                    size="sm"
                                    @click="
                                        $bvModal.show(
                                            'generate_hackathon_results_modal'
                                        )
                                    "
                                    >View Options
                                </base-button>
                            </b-col>
                            <b-col sm="auto">
                                <base-button
                                    type="success"
                                    :pill="true"
                                    size="sm"
                                    @click="start_over()"
                                    >Start Over
                                </base-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-card>

        <!-- #endregion -->
    </div>
</template>

<script>
// Components
import { Select, Option } from "element-ui";

// Queries
import { GET_AI_SERVICES, GET_AI_QUERY } from "@/graphql/queries";
// Mutations
import { CREATE_AI_QUERY } from "@/graphql/mutations";

export default {
    name: "GenerateHackathonCard",
    emits: ["ai_create_hackathon"],
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    },
    props: {},
    apollo: {
        get_hackathon_generator_service: {
            query: GET_AI_SERVICES,
            result(res) {
                let services = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(res.data.allAiService)
                );
                if (services.length > 0) {
                    this.ai.hackathon_generator_service_id = services[0].id;
                }
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    service_name: "hackathon_generator",
                };
            },

            update(data) {
                this.apollo_data.get_hackathon_generator_service = data;
            },
        },
        get_ai_query: {
            query: GET_AI_QUERY,
            result(res) {
                if (res.data.aiQuery.response) {
                    this.ai.string_response = res.data.aiQuery.response;
                    this.ai.response = JSON.parse(res.data.aiQuery.response);
                    clearInterval(this.poll.poller);
                    this.progress_bar.current_value = 100;
                    this.$notify({
                        message: `Your hackathon options have been successfully generated, please click on 'View Options'`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                    graph_utils.disable_query(
                        this.$apollo.queries.get_ai_query
                    );
                }
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    query_id: this.ai.query_id,
                };
            },

            update(data) {
                this.apollo_data.get_ai_query = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_generator_service: {},
                get_ai_query: {},
            },
            modals: {
                generate_hackathon_modal: {
                    form: {
                        values: {
                            hackathon_purpose: null,
                            // hackathon_purpose: "Test",
                            hackathon_goals: null,
                            // hackathon_goals: "Test",
                            participant_types: {
                                options: [
                                    {
                                        name: "Software Developers",
                                    },
                                    {
                                        name: "Engineers",
                                    },
                                    {
                                        name: "UI/UX Designers",
                                    },
                                ],
                                value: null,
                                // value: "UI/UX Designers",
                            },
                            organization_type: {
                                options: [
                                    {
                                        name: "Software Development Agency",
                                    },
                                    {
                                        name: "Systems Integrator",
                                    },
                                    {
                                        name: "Insurance Company",
                                    },
                                ],
                                value: null,
                                // value: "Software Development Agency",
                            },
                        },

                        loading: false,
                        success: false,
                    },
                },
                generate_hackathon_results_modal: {
                    loading: false,
                },
            },

            ai: {
                hackathon_generator_service_id: null,
                query_id: null,
                // query_id: "xxxx",
                string_response: null,
                response: null,
                // #region dummy response
                // response: [
                //     {
                //         hackathon_name: "Innovate for Revenue",
                //         hackathon_short_description:
                //             "Revamp our products and services to increase revenue",
                //         hackathon_detail_description:
                //             "Innovate for Revenue is a hackathon aimed at finding new and creative ways to enhance our products and services in order to increase revenue for our Short Term Insurance Company. By bringing together teams from software development, learning and development, product management, and senior leadership, we can leverage diverse perspectives and expertise to drive innovation. This hackathon is crucial for our company as it will help us explore and implement innovative ideas that can boost our total customer lifetime value and attract new clients.",
                //         hackathon_goals: [
                //             {
                //                 goal: "Develop new features for our insurance platform to enhance customer experience",
                //                 priority: 1,
                //             },
                //             {
                //                 goal: "Create innovative insurance products that cater to specific customer needs",
                //                 priority: 2,
                //             },
                //             {
                //                 goal: "Implement marketing strategies to increase customer retention and acquisition",
                //                 priority: 3,
                //             },
                //         ],
                //         hackathon_judging_criteria: [
                //             {
                //                 criteria:
                //                     "Innovation and creativity of the proposed solution",
                //                 max_score: 30,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "Business impact and potential to increase revenue",
                //                 max_score: 25,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "Feasibility and practicality of implementing the solution",
                //                 max_score: 20,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "User experience and customer satisfaction",
                //                 max_score: 15,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "Presentation and communication skills",
                //                 max_score: 10,
                //                 type: "boolean",
                //             },
                //         ],
                //     },
                //     {
                //         hackathon_name: "RevRevolution",
                //         hackathon_short_description:
                //             "Revolutionize our revenue-generating capabilities",
                //         hackathon_detail_description:
                //             "RevRevolution is a hackathon focused on revolutionizing our revenue-generating capabilities at our Short Term Insurance Company. By bringing together cross-functional teams including software development, learning and development, product management, and senior leadership, we aim to identify and implement cutting-edge solutions that will increase our total customer lifetime value and attract new clients. This hackathon holds great importance for our company as it will enable us to stay ahead of the competition and drive growth through innovative products and services.",
                //         hackathon_goals: [
                //             {
                //                 goal: "Optimize our pricing models to maximize revenue",
                //                 priority: 1,
                //             },
                //             {
                //                 goal: "Develop advanced data analytics models for personalized insurance offerings",
                //                 priority: 2,
                //             },
                //             {
                //                 goal: "Implement automated claims processing system for enhanced efficiency",
                //                 priority: 3,
                //             },
                //         ],
                //         hackathon_judging_criteria: [
                //             {
                //                 criteria:
                //                     "Innovation and originality of the proposed solution",
                //                 max_score: 30,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "Financial impact and potential to increase revenue",
                //                 max_score: 25,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "Technical feasibility and scalability of the solution",
                //                 max_score: 20,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "User-friendliness and convenience of the new system",
                //                 max_score: 15,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "Quality of the presentation and demonstration",
                //                 max_score: 10,
                //                 type: "boolean",
                //             },
                //         ],
                //     },
                //     {
                //         hackathon_name: "ProfitBoost",
                //         hackathon_short_description:
                //             "Boost our profits through innovative ideas",
                //         hackathon_detail_description:
                //             "ProfitBoost is a hackathon dedicated to boosting the profitability of our Short Term Insurance Company through the implementation of innovative ideas. By bringing together teams from software development, learning and development, product management, and senior leadership, we aim to uncover novel approaches to increase our total customer lifetime value and expand our client base. This hackathon is critical for our company's success as it will foster a culture of innovation and result in the development of game-changing solutions that drive revenue growth.",
                //         hackathon_goals: [
                //             {
                //                 goal: "Identify new revenue streams outside of traditional insurance offerings",
                //                 priority: 1,
                //             },
                //             {
                //                 goal: "Enhance customer engagement through digital channels",
                //                 priority: 2,
                //             },
                //             {
                //                 goal: "Streamline internal processes to reduce costs and increase efficiency",
                //                 priority: 3,
                //             },
                //         ],
                //         hackathon_judging_criteria: [
                //             {
                //                 criteria:
                //                     "Degree of innovation and novelty of the proposed solution",
                //                 max_score: 30,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "Financial impact and potential to increase revenue",
                //                 max_score: 25,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "Technical feasibility and scalability of the solution",
                //                 max_score: 20,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "User experience and customer satisfaction",
                //                 max_score: 15,
                //                 type: "sliding_scale",
                //             },
                //             {
                //                 criteria:
                //                     "Presentation skills and clarity of communication",
                //                 max_score: 10,
                //                 type: "boolean",
                //             },
                //         ],
                //     },
                // ],
                // #endregion
            },

            progress_bar: {
                poller: null,
                interval_time: 50,
                increments: (60 * 1000) / 50,
                current_value: 0,
                start_time: null,
            },

            poll: {
                poller: null,
                increments: 5000,
                current_value: 0,
                start_time: null,

            },
        };
    },
    methods: {
        submit_form() {
            this.create_query();
        },

        create_query() {
            this.modals.generate_hackathon_modal.loading = true;

            this.$apollo
                .mutate({
                    mutation: CREATE_AI_QUERY,
                    variables: {
                        service_id: this.ai.hackathon_generator_service_id,
                        request_data: JSON.stringify({
                            hackathon_purpose:
                                this.modals.generate_hackathon_modal.form.values
                                    .hackathon_purpose,
                            hackathon_theme:
                                this.modals.generate_hackathon_modal.form.values
                                    .hackathon_theme,
                            company_type:
                                this.modals.generate_hackathon_modal.form.values
                                    .organization_type.value,
                            hackathon_goals:
                                this.modals.generate_hackathon_modal.form.values
                                    .hackathon_goals,
                            hackathon_participants:
                                this.modals.generate_hackathon_modal.form.values
                                    .participant_types.value,
                        }),
                    },
                })
                .then((res) => {
                    if ("data" in res) {
                        this.$notify({
                            message: `The Hackathon AI is generating your hackathon.`,
                            timeout: 5000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });

                        this.ai.query_id = res.data.aiCreateQuery.query.id;
                        this.start_progressbar();
                        this.start_polling();
                        this.modals.generate_hackathon_modal.success = true;
                        setTimeout(() => {
                            this.modals.generate_hackathon_modal.success = false;
                        }, 1000);

                        setTimeout(() => {
                            this.$bvModal.hide("generate_hackathon_modal");
                        }, 200);
                    } else {
                        this.$notify({
                            message: `Something went wrong: Could not create generate hackathon`,
                            timeout: 5000,
                            icon: "ni ni-cross-bold",
                            type: "danger",
                        });
                    }
                    this.modals.generate_hackathon_modal.loading = false;
                })
                .catch((res) => {
                    this.$notify({
                        message: `Something went wrong: Could not create generate hackathon`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                    this.modals.generate_hackathon_modal.loading = false;
                });
        },

        start_over() {
            this.ai.string_response = null;
            this.ai.response = null;
            this.ai.query_id = null;
            this.$bvModal.show("generate_hackathon_modal");
            graph_utils.disable_query(this.$apollo.queries.get_ai_query);
        },

        start_progressbar() {
            this.progress_bar.current_value = 0;
            this.progress_bar.start_time = Date.now();

            this.progress_bar.poller = setInterval(() => {
                this.progress_bar.current_value +=
                    100 / this.progress_bar.increments;

                // Stop the interval when the progress reaches or exceeds the max value
                if (this.progress_bar.current_value >= 100) {
                    clearInterval(this.progress_bar.poller);
                }
            }, this.progress_bar.interval_time);
        },

        start_polling() {
            this.poll.current_value = 0;
            this.poll.start_time = Date.now();
            this.poll.poller = setInterval(() => {
                this.poll.current_value += this.poll.increments;
                if (this.poll.current_value >= 30000) {
                    if (this.$apollo.queries.get_ai_query.skip) {
                        graph_utils.enable_query(
                            this.$apollo.queries.get_ai_query
                        );
                    } else {
                        this.$apollo.queries.get_ai_query.refetch();
                    }
                }
                if (this.poll.current_value >= 60000) {
                    // In the case that we time out.
                    clearInterval(this.poll.poller);
                    this.$notify({
                        message: `Something went wrong: Could not create generate hackathon`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                    this.ai.query_id = null;
                    graph_utils.disable_query(
                        this.$apollo.queries.get_ai_query
                    );
                }
            }, 5000);
        },

        create_hackathon(hackathon) {
            this.$emit("ai_create_hackathon", hackathon);

            this.modals.generate_hackathon_results_modal.loading = true;
            setTimeout(() => {
                this.$bvModal.hide("generate_hackathon_results_modal");
                this.ai.string_response = null;
                this.ai.response = null;
                this.ai.query_id = null;
                this.modals.generate_hackathon_results_modal.loading = false;
                graph_utils.disable_query(this.$apollo.queries.get_ai_query);
            }, 5000);
        },

        manage_intervals(){
            if (!document.hidden) {
                let progress_passed_ms = Date.now() - this.progress_bar.start_time;
                if (progress_passed_ms > 120000) {
                    this.progress_bar.current_value = 100;
                } else {
                    this.progress_bar.current_value = progress_passed_ms / 120000 * 100;
                }

                let poll_passed_ms = Date.now() - this.poll.start_time;
                if (poll_passed_ms > 120000) {
                    this.poll.current_value = 120000;
                } else {
                    this.poll.current_value = poll_passed_ms;
                }
            }
        }, 
    },
    mounted() {
        this.$apollo.queries.get_ai_query.setOptions({
            fetchPolicy: "network-only",
        });

        document.addEventListener("visibilitychange", this.manage_intervals());
    },
    beforeDestroy() {
        // Remove all intervals
        clearInterval(this.poll.poller);
        clearInterval(this.progress_bar.poller);
        document.removeEventListener("visibilitychange", this.manage_intervals());
    },
};
</script>

<style></style>
